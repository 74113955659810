html{
    overflow-y:scroll;
}
.App {
    font-weight: normal;
    min-height: 100vh;
}

.logo-ebloom {
    background-image: url('../symbols/ebloom_logo.png');
    height: 50px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

/*BUTTON style*/

.ebloom-btn-purple, .ebloom-btn-purple-outline:hover, .ebloom-btn-purple-outline:focus {
    background-color: #B32DDD !important;
    border-color: #B32DDD !important;
    color: white !important;
}

.ebloom-btn-purple-active {
    background-color: #be2edd !important;
    border-color: #B32DDD !important;
    color: white !important;
}

.ebloom-btn-purple-outline {
    background-color: transparent;
    color: #B32DDD;
    border-color: #B32DDD;
}

.ebloom-btn-contrast-blue, .ebloom-btn-contrast-blue-outline:hover, .ebloom-btn-contrast-blue-outline:focus {
    background-color: #00A6EA !important;
    border-color: #00A6EA !important;
    color: white !important;
}

.ebloom-btn-contrast-blue-active {
    background-color: #00A6EA !important;
    border-color: #00A6EA !important;
    color: white !important;
}

.ebloom-btn-contrast-blue-outline {
    background-color: transparent;
    color: #00A6EA;
    border-color: #00A6EA;
}


.ebloom-btn-blue, .ebloom-btn-blue-outline:hover {
    background-color: #62c2e8 !important;
    border-color: #62c2e8 !important;
    color: white !important;
}

.ebloom-btn-blue-outline {
    background-color: transparent;
    color: #62c2e8;
    border-color: #62c2e8;
}

.ebloom-btn-cyan, .ebloom-btn-cyan-outline:hover {
    background-color: #63e8cb !important;
    border-color: #63e8cb !important;
    color: white !important;
}

.ebloom-btn-cyan-outline {
    background-color: transparent;
    color: #63e8cb;
    border-color: #63e8cb;
}

.ebloom-btn-green, .ebloom-btn-green-outline:hover {
    background-color: #37AC41 !important;
    border-color: #37AC41 !important;
    color: white !important;
}

.ebloom-btn-green-active {
    background-color: #9CC333 !important;
    border-color: #37AC41 !important;
    color: white !important;
}

.ebloom-btn-green-outline {
    background-color: transparent;
    color: #37AC41;
    border-color: #37AC41;
}

.ebloom-btn-red, .ebloom-btn-red-outline:hover {
    background-color: #EA1C23 !important;
    border-color: #EA1C23 !important;
    color: white !important;
}

.ebloom-btn-red-active {
    background-color: #E56E1F !important;
    border-color: #EA1C23 !important;
    color: white !important;
}

.ebloom-btn-red-outline {
    background-color: transparent;
    color: #EA1C23;
    border-color: #EA1C23;
}

.ebloom-btn-white, .ebloom-btn-white:hover, .ebloom-btn-white:focus {
    background-color: #FFFFFF !important;
    color: #5D00A2 !important;
    border-color: #5D00A2 !important;
}

.ebloom-add-btn-purple {
    color: #B32DDD;
    cursor: pointer;
}

.ebloom-add-btn-purple:hover {
    background-color: #B32DDD;
    color: white;
    border-radius: 15px;
}

.ebloom-add-btn-blue {
    color: #00A6EA;
    cursor: pointer;
}

.ebloom-add-btn-blue:hover {
    background-color: #00A6EA;
    color: white;
    border-radius: 15px;
}

.ebloom-link-purple {
    color: #B32DDD;
    text-decoration: underline;
    cursor: pointer;
}

.ebloom-link-blue {
    color: #00A6EA;
    text-decoration: underline;
    cursor: pointer;
}

.icon_button:hover {
    background-color: rgba(245, 0, 87, 0.04) !important;
}

.icon_button:focus {
    outline: none;
}

/*LOGO style*/

.noBorder {
    border: none
}

.purple-border {
    border: 1px solid #5D00A2
}

.ebloom-round-logo {
    background-image: url('../symbols/ebloom.png');
    height: 50px;
    width: 50px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.marco-profile {
    background-image: url("../symbols/people/marco.jpg");
    border-radius: 50%;
    height: 100px;
    width: 100px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.margot-profile {
    background-image: url("../symbols/people/margot.jpg");
    border-radius: 50%;
    height: 100px;
    width: 100px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}


.ebloom-logo-v2 {
    background-image: url('../symbols/ebloom_logo.png');
    height: 50px;
    width: 100px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.logo_slack {
    background-image: url("../symbols/logo_slack.png");
    width: 45px;
    height: 37px;
    background-position: center;
    background-size: 37px;
    background-repeat: no-repeat;
}

.logo_slack_large {
    background-image: url("../symbols/logo_slack.png");
    width: 200px;
    height: 150px;
    background-position: center;
    background-size: 150px;
    background-repeat: no-repeat;
}

.logo_mail {
    background-image: url("../symbols/logo_mail.png");
    width: 45px;
    height: 37px;
    background-position: center;
    background-size: 37px;
    background-repeat: no-repeat;
}

.logo_mail_large {
    background-image: url("../symbols/logo_mail.png");
    width: 200px;
    height: 150px;
    background-position: center;
    background-size: 150px;
    background-repeat: no-repeat;
}

.logo_teams {
    background-image: url("../symbols/logo_teams.png");
    width: 45px;
    height: 37px;
    background-position: center;
    background-size: 37px;
    background-repeat: no-repeat;
}

.logo_teams_large {
    background-image: url("../symbols/logo_teams.png");
    width: 200px;
    height: 150px;
    background-position: center;
    background-size: 150px;
    background-repeat: no-repeat;
}

.slack_preview {
    background-image: url("../symbols/slack_preview.PNG");
    width: 900px;
    height: 170px;
    background-position: center;
    background-size: 900px;
    background-repeat: no-repeat;
}

.submit_tips_en {
    background-image: url("../symbols/submit_tips_en.png");
    width: 650px;
    height: 350px;
    background-position: center;
    background-size: 700px;
    background-repeat: no-repeat;
}

.submit_tips_fr {
    background-image: url("../symbols/submit_tips_fr.png");
    width: 650px;
    height: 350px;
    background-position: center;
    background-size: 700px;
    background-repeat: no-repeat;
}

.submit_tips_nl {
    background-image: url("../symbols/submit_tips_nl.png");
    width: 650px;
    height: 350px;
    background-position: center;
    background-size: 700px;
    background-repeat: no-repeat;
}

.checkbox-disabled {
    color: #C4C4C4 !important;
}

.checkbox-purple {
    color: #B32DDD !important;
}

.form-check-input:checked {
    background-color: #B32DDD;
    border-color: #B32DDD;
}

.badge-purple {
    color: #FFFFFF;
    background-color: #5D00A2;
}

.badge-blue {
    color: #FFFFFF;
    background-color: #62c2e8;
}

/*Calendar style*/

.nice-dates-navigation, .nice-dates-day {
    color: #111;
}

.nice-dates-day:before {
    background-color: #00C2EA !important;
}

.nice-dates-day:after {
    border-color: #00C2EA !important;
}

.nice-dates > input:focus-visible {
    outline-color: #00C2EA;
}

.nice-dates > input::placeholder {
    color: #00C2EA;
}

.nice-dates > input {
    padding-left: 5px
}

.nice-dates-day.-today {
    color: #5D00A2;
}

.nice-dates-popover {
    box-shadow: none;
    border: 1px solid #ddd;
    border-radius: 2px;
    max-width: 300px;
    transition: none;
}

/*TEXT style*/

.ebloom-text {
    font-family: "Lato", "Roboto", sans-serif !important;
    font-style: normal;
}

.ebloom-normal-text {
    font-family: "Lato", "Roboto", sans-serif !important;
    font-style: normal !important;
    font-size: 16px !important;
}

.ebloom-large-text {
    font-size: 18px;
}

.ebloom-very-large-text {
    font-size: 20px;
}

.ebloom-xtra-large-text {
    font-size: 22px;
}

.ebloom-small-text {
    font-size: 14px;
}

.ebloom-very-small-text {
    font-size: 12px;
}

.ebloom-normal-title {
    font-family: "Poppins", "Roboto", sans-serif;
    font-size: 32px;
    font-style: normal;
}

.ebloom-title {
    font-family: "Poppins", "Roboto", sans-serif;
}

.ebloom-small-title {
    font-family: "Poppins", "Roboto", sans-serif;
    font-size: 28px;
}

.ebloom-very-small-title {
    font-family: "Poppins", "Roboto", sans-serif;
    font-size: 26px;
}

.bold {
    font-weight: bold;
}

.contrast-purple {
    color: #5D00A2
}

.blue {
    color: #00A6EA;
}

.purple {
    color: #B32DDD
}

.bg-white {
    background-color: white !important;
}

.text-truncate-vertical {
    text-align: left !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important; /*define how many line you want to show */
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}


/*POSITION style*/
.right {
    text-align: right;
    float: right;
}

.left {
    text-align: left;
    float: left;
}

.center {
    text-align: center;
}

.m-auto {
    margin: auto;
}

.mt5 {
    margin-top: 5px;
}

.mb5 {
    margin-bottom: 5px;
}

.ml5 {
    margin-left: 5px;
}

.mr5 {
    margin-right: 5px;
}

.mb10 {
    margin-bottom: 10px;
}

.mt10 {
    margin-top: 10px;
}

.ml10 {
    margin-left: 10px;
}

.mr10 {
    margin-right: 10px;
}

.mb20 {
    margin-bottom: 20px;
}

.mt20 {
    margin-top: 20px;
}

.ml20 {
    margin-left: 20px;
}

.mr30 {
    margin-right: 30px;
}

.mb30 {
    margin-bottom: 30px;
}

.mt30 {
    margin-top: 30px;
}

.ml30 {
    margin-left: 30px;
}

.mr30 {
    margin-right: 30px;
}

.mb40 {
    margin-bottom: 40px;
}

.mt40 {
    margin-top: 40px;
}

.ml40 {
    margin-left: 40px;
}

.mr40 {
    margin-right: 40px;
}

.mb50 {
    margin-bottom: 50px;
}

.mt50 {
    margin-top: 50px;
}

.ml50 {
    margin-left: 50px;
}

.mr50 {
    margin-right: 50px;
}

.mb30 {
    margin-bottom: 30px;
}

.mt30 {
    margin-top: 30px;
}

.ml30 {
    margin-left: 30px;
}

.mr30 {
    margin-right: 30px;
}

.mb40 {
    margin-bottom: 40px;
}

.mt40 {
    margin-top: 40px;
}

.ml40 {
    margin-left: 40px;
}

.mr40 {
    margin-right: 40px;
}

.mb50 {
    margin-bottom: 50px;
}

.mt50 {
    margin-top: 50px;
}

.ml50 {
    margin-left: 50px;
}

.mr50 {
    margin-right: 50px;
}

.t5 {
    position: relative;
    top: 5px
}

.b5 {
    position: relative;
    bottom: 5px
}

.l5 {
    position: relative;
    left: 5px
}

.r5 {
    position: relative;
    right: 5px;
}

.t10 {
    position: relative;
    top: 10px;
}

.b10 {
    position: relative;
    bottom: 10px;
}

.l10 {
    position: relative;
    left: 10px;
}

.r10 {
    position: relative;
    right: 10px;
}

.t15 {
    position: relative;
    top: 15px;
}

.b15 {
    position: relative;
    bottom: 15px;
}

.l15 {
    position: relative;
    left: 15px;
}

.r15 {
    position: relative;
    right: 15px;
}

.t20 {
    position: relative;
    top: 20px;
}

.b20 {
    position: relative;
    bottom: 20px;
}

.l20 {
    position: relative;
    left: 20px;
}

.r20 {
    position: relative;
    right: 20px;
}

.p0 {
    padding: 0;
}

.pt0 {
    padding-top: 0;
}

.pb0 {
    padding-bottom: 0;
}

.pl0 {
    padding-left: 0;
}

.pr0 {
    padding-right: 0;
}

.p5 {
    padding: 5px;
}

.pt2 {
    padding-top: 2px;
}

.pt5 {
    padding-top: 5px;
}

.pb5 {
    padding-bottom: 5px;
}

.pl5 {
    padding-left: 5px;
}

.pr5 {
    padding-right: 5px;
}

.p10 {
    padding: 10px;
}

.pt10 {
    padding-top: 10px;
}

.pb10 {
    padding-bottom: 10px;
}

.pl10 {
    padding-left: 10px;
}

.pr10 {
    padding-right: 10px;
}

.p15 {
    padding: 15px;
}

.pt15 {
    padding-top: 15px;
}

.pb15 {
    padding-bottom: 15px;
}

.pl15 {
    padding-left: 15px;
}

.pr15 {
    padding-right: 15px;
}

.p20 {
    padding: 20px;
}

.pt20 {
    padding-top: 20px;
}

.pb20 {
    padding-bottom: 20px;
}

.pl20 {
    padding-left: 20px;
}

.pr20 {
    padding-right: 20px;
}

.text-break {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.text-line-break {
    white-space: pre-line;
}

.list_notification {
    border: none;
    background-color: white;
    cursor: default;
}

.list_notification_unread {
    border: none;
    background-color: #dfe6e9;
    cursor: default;
}

.list_notification_clickable:hover {
    border: none;
    background-color: #f8f9fa;
    cursor: pointer;
}

.list_notification_unread_clickable:hover {
    border: none;
    background-color: #f8f9fa;
    cursor: pointer;
}

.collaborative_radio {
    background-color: white;
    border-radius: 15px;
    border: 1px solid rgb(93, 0, 162);
    color: rgb(93, 0, 162);
    width: 30px;
    height: 30px;
    padding-left: 8px;
    font-size: 18px;
    font-weight: 600;
    font-family: "Lato", sans-serif;
    cursor: default;
}

.collaborative_radio.multiple {
    border-radius: 5px;
}

.collaborative_radio.selected {
    background-color: rgb(93, 0, 162);
    color: white;
}

.identity_step {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    height: 30px;
    width: max-content;
    border: 1px solid white;
}

.identity_step_first {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.identity_step_last {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.identity_step_selected {
    background-color: #5D00A2;
    color: white;

}

.identity_step_error {
    background-color: #F2F2F2;
    color: #EA1C23;
}

.identity_step_valid {
    background-color: #37AC41;
    color: white;
}

.identity_step_empty {
    background-color: #F2F2F2;
    color: black;

}

.pointer {
    cursor: pointer;
}

.cursorDefault {
    cursor: default;
}

.very_small_textfield > .MuiInputBase-root, .MuiFormControl-root.very_small_textfield {
    height: 30px !important;
}

.menu-border-contrast-blue > .MuiMenu-paper {
    border: 1px solid #00A6EA;
}

.menu-border-contrast-purple > .MuiMenu-paper {
    border: 1px solid #5D00A2;
}

.menu-border-purple > .MuiMenu-paper {
    border: 1px solid #B32DDD;
}

.menu-border-grey > .MuiMenu-paper {
    border: 1px solid #8D8D8D;
}

.navDrawer .MuiDrawer-paper {
    width: 240px;
    z-index: 300 !important;
}

.border-1 {
    border: 1px solid;
}

.webloom-progress-date {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.contrast-purple-icon > .custom-icon, .contrast-purple-icon > g > .custom-icon {
    fill: #5D00A2;
}

.half-contrast-purple-icon > g > .left-half, .border {
    fill: #5D00A2;
}

.border-contrast-purple-icon > g > .border {
    fill: #5D00A2;
}

.border-contrast-blue-icon > g > .border {
    fill: #00A6EA;
}

.contrast-blue-icon > .custom-icon, .contrast-blue-icon > g > .custom-icon {
    fill: #00A6EA;
}

.purple-icon > .custom-icon, .purple-icon > g > .custom-icon {
    fill: #B32DDD;
}

.purple-icon-stroke > .custom-icon, .purple-icon-stroke > g > .custom-icon {
    stroke: #B32DDD;
    fill: none;
}

.purple-icon-fill-stroke > .custom-icon, .purple-icon-fill-stroke > g > .custom-icon, .purple-icon-stroke:hover > .custom-icon {
    stroke: #B32DDD;
    fill: #B32DDD
}

.border-purple-icon > g > .border {
    fill: #B32DDD
}

.border-grey-icon > g > .border {
    fill: #bdbdbd
}

.grey-icon > .custom-icon, .grey-icon > g > .custom-icon {
    fill: #bdbdbd;
}

.green-icon > .custom-icon, .green-icon > g > .custom-icon {
    fill: #37AC41;
}

.red-icon > .custom-icon, .red-icon > g > .custom-icon {
    fill: #EA1C23;
}

.white-icon > .custom-icon, .white-icon > g > .custom-icon {
    fill: #FFFFFF;
}

.half-white-icon > g > .left-half, .border {
    fill: #FFFFFF;
}

.border-white-icon > g > .border {
    fill: #FFFFFF;
}

.opacity-icon > .custom-icon, .opacity-icon > g > .custom-icon {
    opacity: 0.3;
}

.low-opacity-icon > .custom-icon, .opacity-icon > g > .custom-icon {
    opacity: 0.1;
}

.menu-icon>.custom-icon, .menu-icon>g>.custom-icon{
    fill: #000000;
    opacity: 0.5;
}

/*Speedometer eBloom class*/
.score50>g>.segment50, .score55>g>.segment50, .score55>g>.segment55, .score60>g>.segment50, .score60>g>.segment55, .score65>g>.segment50, .score65>g>.segment55, .score70>g>.segment50, .score70>g>.segment55, .score75>g>.segment50, .score75>g>.segment55, .score80>g>.segment50, .score80>g>.segment55, .score85>g>.segment50, .score85>g>.segment55, .score90>g>.segment50, .score90>g>.segment55, .score95>g>.segment50, .score95>g>.segment55{
    fill:#FDDB01
}

.score60>g>.segment60, .score65>g>.segment60, .score65>g>.segment65, .score70>g>.segment60, .score70>g>.segment65, .score70>g>.segment70, .score75>g>.segment60, .score75>g>.segment65, .score75>g>.segment70, .score75>g>.segment75, .score80>g>.segment60, .score80>g>.segment65, .score80>g>.segment70, .score80>g>.segment75, .score85>g>.segment60, .score85>g>.segment65, .score85>g>.segment70, .score85>g>.segment75, .score90>g>.segment60, .score90>g>.segment65, .score90>g>.segment70, .score90>g>.segment75, .score95>g>.segment60, .score95>g>.segment65, .score95>g>.segment70, .score95>g>.segment75{
    fill:#9CC333
}
.score80>g>.segment80, .score85>g>.segment80, .score85>g>.segment85, .score90>g>.segment80, .score90>g>.segment85, .score90>g>.segment90, .score95>g>.segment80, .score95>g>.segment85, .score95>g>.segment90, .score95>g>.segment95{
    fill:#37AC41
}

.score45>g>.speedometer-danger{
    opacity: 1;
}

.scoreNeg>g>.speedometer-danger{
    opacity: 0;
}

.yes-no>g>.segment50, .yes-no>g>.segment55, .yes-no>g>.segment60, .yes-no>g>.segment65, .yes-no>g>.segment70{
    fill:#B32DDD
}

.yes-no>g>.segment75, .yes-no>g>.segment80, .yes-no>g>.segment85, .yes-no>g>.segment90, .yes-no>g>.segment95{
    fill:#00A6EA
}


.hide-legend>.speedometer-left-text, .hide-legend>.speedometer-top-text, .hide-legend>.speedometer-right-text{
    opacity: 0;
}

.modal-content{
    border-radius: 7px;
}

/* width */
.overflow-y-auto::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
.overflow-y-auto::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 50px;
}
/* Track */
.overflow-y-auto-blue::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 50px;
}
/* Track */
.overflow-y-auto-purple::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 50px;
}

/* Track */
.overflow-y-auto-grey::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 50px;
}

/* Track */
.overflow-y-auto-custom::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 50px;
}

/* Handle */
.overflow-y-auto-custom::-webkit-scrollbar-thumb {
    background: var(--background-color) !important;
}

/* Handle */
.overflow-y-auto::-webkit-scrollbar-thumb {
    background: #5D00A2;
    border-radius: 50px;
}
/* Handle */
.overflow-y-auto-blue::-webkit-scrollbar-thumb {
    background: #00A6EA;
    border-radius: 50px;
}

/* Handle */
.overflow-y-auto-purple::-webkit-scrollbar-thumb {
    background: #B32DDD;
    border-radius: 50px;
}

/* Handle */
.overflow-y-auto-grey::-webkit-scrollbar-thumb {
    background: #8D8D8D;
    border-radius: 50px;
}

/* width */
.overflow-x-auto::-webkit-scrollbar {
    height: 5px;
}

/* Track */
.overflow-x-auto::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.overflow-x-auto::-webkit-scrollbar-thumb {
    background: #5D00A2;
}

.ebloom-comment-arrow > .popover-arrow::after {
    border-bottom-color: var(--border-color);
    overflow: visible !important;
}
.ebloom-comment-arrow > .popover-arrow::before {
    border-bottom-color: #5D00A2;
    overflow: visible !important;

}
.ebloom-divider > .list-group-item:first-child {
    border-top : 0 none;
}
.ebloom-divider > .list-group-item {
    border-top : 1px solid var(--border-color);
}


/*Calendar Style*/



/* Animation */

@keyframes ebloom-ring {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(15deg);
    }
    20% {
        transform: rotate(-10deg);
    }
    30% {
        transform: rotate(5deg);
    }
    40% {
        transform: rotate(-5deg);
    }
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

/* Appliquer l'animation à l'élément SVG */
.ebloom-ring {
    animation: ebloom-ring 1s ease-in-out infinite;
}

/* PRODUCT TOUR */
.tour-between-step {
    width: 100%;
    height: 8px;
}

.tour-text-finished {
    color: #5D00A2;
}

.tour-text-not-started {
    color: #DBCAE7;
}

.tour-finished {
    background-color: #5D00A2;
}

.tour-not-finished {
    background-color: #C78AE0;
}

.tour-not-started {
    background-color: #DBCAE7;
}

.tour-svg {
    width: 30px;
    height: 30px;
}

.tour-text {
    font-weight: 500;
    font-size: 0.8rem;
}

.tour-next-icon {
    width: 15px;
    height: 15px;
}

.mini-tour-finished {
    background-color: #5D00A2;
}

.mini-tour-not-finished {
    background-color: #C78AE0
}

.mini-tour-not-started {
    background-color: #DBCAE7;
}

.mini-tour-svg {
    width: 20px;
    height: 20px;
}

.mini-tour-between-step {
    width: 100%;
    height: 5px;
}

.tour-tooltip-box {
    border-radius: 5px;
    background-color: white;
    color: #5D00A2;
    box-sizing: border-box;
    font-size: 16px;
    padding: 15px;
    position: relative;
    max-width: 390px;
    min-width: 200px;
    text-align: center;
}

/* MyBloom */
.selectedAsset {
    background-color: #daf4fb !important;
    border: 4px solid #07B7E3;
}
.selectedMenu{
    background-color: #D79DED;
}
.selectedMenu .logoMenu {
    fill: white;
}
.menu-hidden {
    visibility: hidden;
}

/*Notification sonar*/
@keyframes sonar {
    0% {
        transform: scale(.9);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

.sonar{
    animation: sonar 1.5s infinite;
}

/*Navbar*/
.gradient-app-bar {
    background: linear-gradient(to left,var(--linear-gradient-color) 78%, #5D00A2 );
}

/*Accordion*/
.ebloom-accordion > .accordion-button {
    background-color: white ;
    color : #5D00A2;
    box-shadow: none;
}

.ebloom-accordion > .accordion-button:focus {
    box-shadow: none;
}

.ebloom-accordion > .accordion-button:not(.collapsed) {
    background-color: white;
    border-radius: 10px 10px 0 0;
    border-width: 1px ;
    border-style: solid;
    border-color: #5D00A2;
    color : #5D00A2 ;
    box-shadow: white;

}

.ebloom-accordion > .accordion-button:not(.collapsed)::after {
    color: #0f5132;

}
