.smileyInactive{
    opacity: 0.3;
}

.smileyStayInactive{
    opacity: 0.3;
}

.smileyInactive:hover{
    opacity: 1;
}

.smileyUnhappy {
    background-image: url('../symbols/smiley_unhappy.png');
}

.smileySad {
    background-image: url('../symbols/smiley_sad.png');
}

.smileyPokerface {
    background-image: url('../symbols/smiley_poker-face.png');
}

.smileyGlad {
    background-image: url('../symbols/smiley_glad.png');
}

.smileyHappy {
    background-image: url('../symbols/smiley_happy.png');
}

.smileyAnonymous {
    background-image: url("../symbols/smiley_anonymous.png");
}

.smileyUnhappyGrey {
    background-image: url("../symbols/smiley_unhappy_grey.png");
}

.smileySadGrey {
    background-image: url("../symbols/smiley_sad_grey.png");
}

.smileyPokerFaceGrey {
    background-image: url("../symbols/smiley_poker-face_grey.png");
}

.smileyGladGrey {
    background-image: url("../symbols/smiley_glad_grey.png");
}

.smileyHappyGrey {
    background-image: url("../symbols/smiley_happy_grey.png");
}

.flag{
    background-image: url("../symbols/flag_white.png");
}

.flag.active{
    background-image: url("../symbols/flag_active.png");
}

.flag.grey{
    background-image: url("../symbols/flag_grey.png");
}

.flag_contrast{
    background-image: url("../symbols/flag_contrast.png");
}

.hands_icon{
    background-image: url("../symbols/hands_icon.png");
}

.hands_red_icon{
    background-image: url("../symbols/hands_red_icon.png");
}

.users_group_icon{
    background-image: url("../symbols/users-group_icon.png");
}

.dialog_icon_purple_contrast{
    background-image: url("../symbols/dialog_icon_purple_contrast.png");
}

.dialog_icon_purple{
    background-image: url("../symbols/dialog_icon_purple.png");
}

.dialog_icon_grey{
    background-image: url("../symbols/dialog_icon_grey.png");
}

.dialog_icon_blue{
    background-image: url("../symbols/dialog_icon_blue.png");
}

.info_icon{
    background-image: url("../symbols/info_icon_purple.png");
}

.info_icon_blue{
    background-image: url("../symbols/info_icon_blue.png");
}

.info_icon_blue_contrast{
    background-image: url("../symbols/info_icon_blue_contrast.png");
}

.work_icon{
    background-image: url("../symbols/categories/work_lifestyle_bubble_icon.svg");
}

.team_icon{
    background-image: url("../symbols/categories/team_interaction_bubble_icon.svg");
}

.management_icon{
    background-image: url("../symbols/categories/management_leadership_bubble_icon.svg");
}

.policy_icon{
    background-image: url("../symbols/categories/policy_support_bubble_icon.svg");
}

.adhesion_icon{
    background-image: url("../symbols/categories/adhesion_culture_bubble_icon.svg");
}

.work_icon_small{
    background-image: url("../symbols/work_lifestyle.png");
}

.team_icon_small{
    background-image: url("../symbols/team_interaction.png");
}

.management_icon_small{
    background-image: url("../symbols/management_leadership.png");
}

.policy_icon_small{
    background-image: url("../symbols/policy_support.png");
}

.adhesion_icon_small{
    background-image: url("../symbols/adhesion_culture.png");
}

.nbCB_icon{
    background-image: url("../symbols/icon_nbCB.png");
}

.danger_icon{
    background-image: url("../symbols/danger_icon.png");
}

.profile_icon{
    background-image: url("../symbols/icon_profil.png");
}

.heart_icon{
    background-image: url("../symbols/icon_heart.png");
}

.heart_purple_icon{
    background-image: url("../symbols/icon_heart_purple.png");
}

.heart_white_icon{
    background-image: url("../symbols/icon_heart_white.png");
}

.check_purple_icon{
    background-image: url("../symbols/check_icon_purple.png");
}

.basic_medium_smile{
    width: 60px;
    height: 60px;
    background-position: center;
    background-size: 50px;
    background-repeat: no-repeat;

}

.basic_small_smile{
    width: 45px;
    height: 45px;
    background-position: center;
    background-size: 37px;
    background-repeat: no-repeat;
}

.micro_icon{
    width: 25px;
    height: 25px;
    background-position: center;
    background-size: 20px;
    background-repeat: no-repeat;
}

.very_small_smile{
    width: 30px;
    height: 30px;
    background-position: center;
    background-size: 25px;
    background-repeat: no-repeat;

}

.xtra_small_smile{
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: 15px;
    background-repeat: no-repeat;
}

.danger_report_size{
    width: 25px;
    height: 19px;
    background-position: center;
    background-size: 25px;
    background-repeat: no-repeat;
}

.very_small_category{
    width: 25px;
    height: 25px;
    background-position: center;
    background-size: 17px;
    background-repeat: no-repeat;
}

.medium_smile{
    width: 90px;
    height: 90px;
    background-position: center;
    background-size: 75px;
    background-repeat: no-repeat;
}

.small_smile{
    width: 60px;
    height: 60px;
    background-position: center;
    background-size: 50px;
    background-repeat: no-repeat;
}

.basic_smile{
    width: 120px;
    height: 120px;
    background-position: center;
    background-size: 100px;
    background-repeat: no-repeat;
}

.xtra_small_icon{
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: 15px;
    background-repeat: no-repeat;
}

.very_small_icon{
    width: 25px;
    height: 25px;
    background-position: center;
    background-size: 20px;
    background-repeat: no-repeat;
}

.small_icon{
    width: 30px;
    height: 30px;
    background-position: center;
    background-size: 25px;
    background-repeat: no-repeat;
}

.medium_icon{
    width: 35px;
    height: 35px;
    background-position: center;
    background-size: 30px;
    background-repeat: no-repeat;
}

.large_icon{
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: 35px;
    background-repeat: no-repeat;
}

.very_large_icon{
    width: 45px;
    height: 45px;
    background-position: center;
    background-size: 40px;
    background-repeat: no-repeat;
}

.xtra_large_icon{
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: 45px;
    background-repeat: no-repeat;
}

.basic_scale{
    width:100%;
    margin:auto;
}

.large_scale{
    width:75%;
    margin:auto;
}

.large_smile{
    width: 100px;
    height: 100px;
    background-position: center;
    background-size: 80px;
    background-repeat: no-repeat;
}

.rotate{
    transform: rotate(-90deg);
}

.inline_icon{
    display: inline-block;
}

.segment-value{
    font-weight: normal !important;
    font-family: "Lato";
}

.segment-label{
    font-weight: normal;
    font-family: "Lato", "Roboto", sans-serif;
}

